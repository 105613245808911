import EncryptUtility from "../../utility/js/encrypt-utility.js";
import AddFavourite from "../../utility/js/addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
import moment from "moment";
import commonAPIService from "../../common/js/common-api-service.js";
import shippingService from "./shipping-service.js";
import Utility from "../../../shared/utility";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      secondaryLabelSize: EncryptUtility.localStorageDecrypt("secondaryLabelSize"),
      secondaryPrinterType: EncryptUtility.localStorageDecrypt("secondaryPrinterType"),
      secondaryName: EncryptUtility.localStorageDecrypt("secondaryName"),
      defaultName: EncryptUtility.localStorageDecrypt("defaultName"),
      showPrimary: false,
      searchLPNToShip: "",
      searchDisabled: false,
      isFormValid: false,
      showLPNDetails: false,
      lpnDetails: [],
      selectedVendor: "",
      vendorData: [],
      vendorSelected: false,
      vendorDetails: [],
      showVendorDetails: false,
      carrierList: [],
      selectWaybill: "",
      disabledWaybill: true,
      searchFieldDisabled: false,
      editVendorDialog: false,
      vendorTitle: "",
      editAllowed: true,
      vendorCompany: "",
      vendorFirst: "",
      vendorLast: "",
      vendorAddress1: "",
      vendorAddress2: "",
      vendorCity: "",
      vendorState: "",
      vendorZip: "",
      vendorCountry: "USA",
      vendorEmail: "",
      vendorPhone1: "",
      vendorPhone2: "",
      vendorCell: "",
      vendorFax: "",
      countryData: [],
      stateData: [],
      packslipDetails: [],
      required: [(v) => !!v || "Field is required"],
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character"],
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Enter valid Email"],
      headersLPNDetails: [
        {
          text: "Part #",
          align: "start",
          value: "Part#",
          class: "primary customwhite--text",
        },
        {
          text: "Quantity",
          value: "Quantity",
          class: "primary customwhite--text",
        },
        {
          text: "Warehouse",
          value: "Warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Location",
          value: "Location",
          class: "primary customwhite--text",
        },
      ],
      printerConfigured: false,
      showCountryDrop: false,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.searchdisable = true;
    let countryStateData = await commonAPIService.countryListNew(this.userId, "", 1, "get");
    this.countryData = countryStateData.CountryInfo;
    if (this.defaultName !== null && this.defaultName !== undefined) {
      if (this.secondaryLabelSize !== null && this.secondaryLabelSize !== undefined) {
        if (this.secondaryPrinterType !== null && this.secondaryPrinterType !== undefined) {
          this.searchFieldDisabled = false;
          this.searchDisabled = false;
          this.printerConfigured = false;
        }
      } else {
        this.searchFieldDisabled = true;
        this.searchDisabled = true;
        this.printerConfigured = true;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Shipping Printer is not configured, please configure printer",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    } else {
      this.searchFieldDisabled = true;
      this.searchDisabled = true;
      this.printerConfigured = true;
      let Alert = {
        type: "error",
        isShow: true,
        message: "Default Printer is not configured, please configure printer",
      };
      this.$store.commit("ConfigModule/Alert", Alert);
    }
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.searchLPNToShip = "";
      this.searchDisabled = false;
      this.showLPNDetails = false;
      this.lpnDetails = [];
      this.selectedVendor = "";
      this.vendorData = [];
      this.vendorSelected = false;
      this.showVendorDetails = false;
      this.vendorDetails = [];
      this.carrierList = [];
      this.selectWaybill = "";
      this.disabledWaybill = true;
      this.selectServiceLvlCarrier = "";
      this.lpnDetails = [];
      this.searchFieldDisabled = false;
      this.printerConfigured = false;
      this.$refs.lpnSearchForm.resetValidation();
    },
    //Search LPN To Ship
    async searchLPN() {
      if (this.$refs.lpnSearchForm.validate()) {
        let searchObj = {
          InJson: JSON.stringify({
            pass: 1,
            lpn: this.searchLPNToShip,
            user_id: this.userId,
          }),
        };
        let showMessage = false;
        let res = await shippingService.shippingBulkLPN("post", searchObj, showMessage);
        if (res !== undefined) {
          if (res.message !== "NA") {
            this.showLPNDetails = true;
            this.lpnDetails = res.Result;
            this.searchDisabled = true;
            this.getVendorData();
            this.searchFieldDisabled = true;
          }
        }
      }
    },
    //Vendor Data
    async getVendorData() {
      let searchObj = {
        InJson: JSON.stringify({
          pass: 0,
        }),
      };
      let showMessage = false;
      let res = await shippingService.shippingBulkLPN("post", searchObj, showMessage);
      if (res !== undefined) {
        if (res.message !== "NA") {
          this.vendorData = res.Result;
        }
      }
    },
    //On Vendor Selection
    async vendorSelection() {
      if (this.selectedVendor !== null && this.selectedVendor !== undefined) {
        let obj = {
          InJson: JSON.stringify({
            pass: 2,
            lpn: this.searchLPNToShip,
            user_id: parseInt(this.userId),
            vend_id: this.selectedVendor,
          }),
        };
        let showMessage = false;
        let res = await shippingService.shippingBulkLPN("post", obj, showMessage);
        if (res !== undefined) {
          if (res.message !== "NA") {
            if (res.Result2.length !== 0) {
              this.disabledWaybill = true;
              this.vendorDetails = res.Result1;
              this.carrierList = res.Result2;
              this.showVendorDetails = true;
              this.selectServiceLvlCarrier = "";
              this.selectWaybill = "";
              if (this.$refs.carrierSubmitForm) {
                this.$refs.carrierSubmitForm.resetValidation();
              }
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "No Carriers/Service Levels set up for this Project/Business",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          }
        }
      }
    },
    //Edit Vendor Details
    editVendorDetails() {
      this.editVendorDialog = true;
      this.vendorCompany = this.vendorDetails[0].rcompany;
      this.vendorTitle = this.vendorDetails[0].rtitle;
      this.vendorFirst = this.vendorDetails[0].rfirst;
      this.vendorLast = this.vendorDetails[0].rlast;
      this.vendorAddress1 = this.vendorDetails[0].raddress1;
      this.vendorAddress2 = this.vendorDetails[0].raddress2;
      this.vendorCity = this.vendorDetails[0].rcity;
      this.vendorCountry = this.vendorDetails[0].rcountry;
      this.countrySelected();
      this.vendorState = this.vendorDetails[0].rstate;
      this.vendorZip = this.vendorDetails[0].rzip;
      this.vendorEmail = this.vendorDetails[0].rintaddr;
      this.vendorPhone1 = this.vendorDetails[0].rphone1;
      this.vendorPhone2 = this.vendorDetails[0].rphone2;
      this.vendorCell = this.vendorDetails[0].rcell;
      this.vendorFax = this.vendorDetails[0].rfax;
    },
    //Close button in the popup
    closeDialog() {
      this.editAllowed = true;
      this.vendorCompany = "";
      this.vendorTitle = "";
      this.vendorFirst = "";
      this.vendorLast = "";
      this.vendorAddress1 = "";
      this.vendorAddress2 = "";
      this.vendorCity = "";
      this.vendorState = "";
      this.vendorZip = "";
      this.vendorCountry = "USA";
      this.vendorEmail = "";
      this.vendorPhone1 = "";
      this.vendorPhone2 = "";
      this.vendorCell = "";
      this.vendorFax = "";
      this.showCountryDrop = false;
      this.$refs.lpnVendorDetailsForm.resetValidation();
      this.editVendorDialog = false;
    },
    //Submit button in the Vendor Details popup
    submitVendorDetails() {
      if (this.$refs.lpnVendorDetailsForm.validate()) {
        this.vendorDetails[0].rcompany = this.vendorCompany;
        this.vendorDetails[0].rtitle = this.vendorTitle;
        this.vendorDetails[0].rfirst = this.vendorFirst;
        this.vendorDetails[0].rlast = this.vendorLast;
        this.vendorDetails[0].raddress1 = this.vendorAddress1;
        this.vendorDetails[0].raddress2 = this.vendorAddress2;
        this.vendorDetails[0].rcity = this.vendorCity;
        this.vendorDetails[0].rstate = this.vendorState;
        this.vendorDetails[0].rzip = this.vendorZip;
        this.vendorDetails[0].rcountry = this.vendorCountry;
        this.vendorDetails[0].rintaddr = this.vendorEmail;
        this.vendorDetails[0].rphone1 = this.vendorPhone1;
        this.vendorDetails[0].rphone2 = this.vendorPhone2;
        this.vendorDetails[0].rcell = this.vendorCell;
        this.vendorDetails[0].rfax = this.vendorFax;
        this.closeDialog();
      }
    },
    //LPN Submit check vendor address
    vendorAddCheck() {
      if (this.$refs.carrierSubmitForm.validate()) {
        if (this.selectServiceLvlCarrier !== "" && this.selectServiceLvlCarrier !== null) {
          if (this.selectWaybill !== "" && this.selectWaybill !== null) {
            if (this.vendorDetails[0].raddress1 == "") {
              let Alert = {
                type: "error",
                isShow: true,
                message: "Invalid Address 1 of the Vendor",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              if (this.vendorDetails[0].rcity == "") {
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: "Invalid Address 1 and City of the Vendor",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                if (this.vendorDetails[0].rzip == "") {
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "Invalid Address 1, City and Zip/Postal Code of the Vendor",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
              }
            } else {
              this.submitLPNDetails();
            }
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: "Please provide Waybill details",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select any Carrier/Service Level",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //LPN Submit button click event
    async submitLPNDetails() {
      if (EncryptUtility.localStorageDecrypt("defaultName") !== null && EncryptUtility.localStorageDecrypt("defaultName") !== undefined) {
        const date = moment();
        let submitObj = {
          InJson: JSON.stringify({
            pass: 3,
            lpn: this.searchLPNToShip,
            user_id: parseInt(this.userId),
            vend_id: this.selectedVendor,
            rfirst: this.vendorDetails[0].rfirst,
            rcompany: this.vendorDetails[0].rcompany,
            raddress1: this.vendorDetails[0].raddress1,
            raddress2: this.vendorDetails[0].raddress2,
            rcity: this.vendorDetails[0].rcity,
            rstate: this.vendorDetails[0].rstate,
            rzip: this.vendorDetails[0].rzip,
            rcountry: this.vendorDetails[0].rcountry,
            rphone1: this.vendorDetails[0].rphone1,
            rphone2: this.vendorDetails[0].rphone2,
            rcell: this.vendorDetails[0].rcell,
            rfax: this.vendorDetails[0].rfax,
            rintaddr: this.vendorDetails[0].rintaddr,
            slevel_key: this.selectServiceLvlCarrier,
            waybill: this.selectWaybill,
            packslip: "P" + date.format("YYMMDDHHmmssSS"),
            lpnKey: parseInt(this.lpnDetails[0].lpn_key),
          }),
        };
        let showMessage = true;
        let res = await shippingService.shippingBulkLPN("post", submitObj, showMessage);
        if (res !== undefined) {
          if (res.message !== "NA") {
            if (res.pdf_url !== "" && res.pdf_url !== undefined) {
              Utility.printZPLPDF(res.pdf_url, this.defaultName);
            }
            if (res.final_label !== "" && res.final_label !== undefined) {
              PrinterDetails.printLabelData(res.final_label, this.secondaryName);
            }
            this.resetFunction();
          }
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Default Printer is not configured, please configure printer.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Generic Shipping label
    // async genericShippingLabel() {
    //   let printShipping = {
    //     user_id: parseInt(this.userId),
    //     ord_type: "P",
    //     ord_key: parseInt(this.submitResponse[0].po_key),
    //     box_key: parseInt(this.searchLPNToShip),
    //     waybill: parseInt(this.selectWaybill),
    //   };
    //   let showMessage = false;
    //   let res = await shippingService.shippingGenericLabel("post", printShipping, showMessage);
    //   if (res !== undefined) {
    //     if (res.message !== "NA") {
    //       PrinterDetails.printLabelData(res, this.secondaryName);
    //     }
    //   }
    // },
    //getting packslip
    // async packSlip() {
    //   let obj = {
    //     box_key: 0,
    //     lpn_key: parseInt(this.lpnDetails[0].lpn_key),
    //     packslip: "",
    //     ordno: "",
    //     user_key: parseInt(this.userId),
    //     po_so: "P",
    //     order_key: parseInt(this.submitResponse[0].po_key),
    //   };
    //   let showMessage = false;
    //   let res = await shippingService.shippingPackSlip("post", obj, showMessage);
    //   if (res !== undefined) {
    //     if (res.message !== "NA") {
    //       this.packslipDetails = [];
    //       this.packslipDetails = res.Result1;
    //       if (this.packslipDetails.length !== 0) {
    //         this.packslipReport();
    //       }
    //     }
    //   }
    // },
    //Report Printing
    // async packslipReport() {
    //   let obj = {
    //     box_key: 0,
    //     lpn_key: parseInt(this.lpnDetails[0].lpn_key),
    //     packslip: this.packslipDetails[0].packslip,
    //     ordno: "",
    //     user_key: parseInt(this.userId),
    //     po_so: "P",
    //     order_key: parseInt(this.submitResponse[0].po_key),
    //   };
    //   let showMessage = false;
    //   let res = await shippingService.shippingPackSlip("post", obj, showMessage);
    //   if (res !== undefined) {
    //     if (res.message !== "NA") {
    //       window.open(res, "_blank");
    //       this.resetFunction();
    //     }
    //   }
    // },
    //Printer Configured
    printerDetails(labelPrinter) {
      if (!labelPrinter) {
        this.searchFieldDisabled = false;
        this.searchDisabled = false;
        this.printerConfigured = false;
      } else {
        this.searchFieldDisabled = true;
        this.searchDisabled = true;
        this.printerConfigured = true;
      }
    },
    //Country in add popup once selected
    async countrySelected() {
      if (this.editVendorDialog && this.vendorCountry !== "") {
        this.vendorState = "";
        let countryStateData = await commonAPIService.countryListNew(this.userId, this.vendorCountry, 2, "get");
        this.stateData = countryStateData.StateInfo;
        if (this.stateData.length !== 0) {
          this.showCountryDrop = true;
        } else {
          this.showCountryDrop = false;
        }
      }
    },
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
